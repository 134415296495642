import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StoreContext } from 'index';
import { Card, CardBody } from 'components/card';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import ProgressBar from 'components/progress-bar/ProgressBar';
import { Button, ButtonsGroup } from 'components/button';
import { getTestSessionURL } from 'utils/url';
import { openNewWindow } from 'utils/browser';
import Icon from 'components/icon/Icon';
import { MenuWrapper } from 'components/menu';
import CopyUrlModal from 'containers/partials/modals/CopyUrlModal';
import FinishSessionButton from '../buttons/FinishSessionButton';
import { useSessionContext } from 'contexts/SessionContext';
import SessionFormInput from 'containers/pages/sessions/SessionFormInput';
import useEdit from 'hooks/utils/useEdit';

function EditSessionCard({ ...props }) {
  const {
    authStore: { user },
    uiState: { locale }
  } = useContext(StoreContext);
  const { entityId, sessionId } = useParams();
  const { session } = useSessionContext();
  const [edit, setEdit] = useEdit(props.edit, true);
  const [copyUrlModal, setCopyUrlModal] = useState(false);

  const sessionUrl = session?.id // && session?.config?.type
    ? getTestSessionURL({
        entityId,
        sessionId,
        packageType: session?.config?.type ?? 'talent',
        locale,
        sporterId: null,
        session,
        copyAble: true
      })
    : '';

  console.log('sessionUrl', session);

  return (
    <Card secondary multiBody>
      {copyUrlModal && sessionUrl && (
        <CopyUrlModal
          isOpen={copyUrlModal}
          content={`${sessionUrl}`}
          onClose={() => setCopyUrlModal(false)}
        />
      )}
      {!user.isAthlete() && !session.isDone && (
        <CardBody progressBar>
          {session.finishedPercentage === 0 &&
            session.personEntities.length !== 0 &&
            !session.isMTS &&
            !session.isSAP && (
              <p>
                <FormattedMessage {...messages.labelSessionNoTests} />
              </p>
            )}
          {session.personEntities.length === 0 && (
            <p
              className={
                'o-flex o-flex--column o-flex--align-center u-margin-bottom-none'
              }
            >
              <FormattedMessage {...messages.labelSessionNoSporters} />
              <br />
              {/* <FormattedMessage {...messages.labelSessionPleaseAddSporters} /> */}

              {/*<Button
                extraClassNames={'u-margin-top-small'}
                rounded
                primary
                onClick={() =>
                  push(
                    generatePath(ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER, {
                      entityId,
                      sessionsTab,
                      sessionId
                    })
                  )
                }
              >
                <Icon id="add-white" />
                <FormattedMessage {...messages.buttonSessionAddSporters} />
              </Button>*/}
            </p>
          )}
          {session.inProgress && !session.isMTS && !session.isSAP && (
            <>
              <p>
                <FormattedMessage
                  {...messages.labelSessionInProgress}
                  values={{
                    percentage: session.finishedPercentage
                  }}
                />
              </p>
              <ProgressBar secondary value={session.finishedPercentage} />
            </>
          )}
          {session.personEntities.length !== 0 && !session.isMTS && (
            <ButtonsGroup halfButtons extraClassNames="o-flex--justify-center">
              <Button
                halfRounded
                primary
                onClick={() => {
                  const url = getTestSessionURL({
                    entityId,
                    sessionId,
                    packageType: session?.config?.type ?? 'talent',
                    locale: user.baseLanguage
                  });
                  openNewWindow(url);
                }}
              >
                <Icon id="test-white" />
                {session.isNotStarted ? (
                  <FormattedMessage {...messages.buttonSessionStartSession} />
                ) : (
                  <FormattedMessage
                    {...messages.buttonSessionContinueSession}
                  />
                )}
              </Button>
              <MenuWrapper
                trigger={
                  <Button halfRounded primary>
                    <Icon id="dropdown-open" fillColor="color-neutral" />
                  </Button>
                }
              >
                <Button
                  menu
                  onClick={() => {
                    const url = getTestSessionURL({
                      entityId,
                      sessionId,
                      packageType: session?.config?.type ?? 'talent',
                      locale
                    });
                    openNewWindow(url);
                  }}
                >
                  <FormattedMessage {...messages.menuItemSessionOpenTestApp} />
                  <Icon id="test-gray" />
                </Button>
                <Button menu onClick={() => setCopyUrlModal(true)}>
                  <FormattedMessage {...messages.menuItemSessionCopyUrl} />
                  <Icon id="link" />
                </Button>
                {/*{authStore.user.canFinishSessions() && (
                  <FinishSessionButton
                    menu
                    sessionId={sessionId}
                    entityId={entityId}
                    name={session.name}
                    testSetId={session.testSets[0].id}
                  >
                    <FormattedMessage
                      {...messages.menuItemSessionFinishSession}
                    />
                    <Icon id="send" />
                  </FinishSessionButton>
                )}*/}
              </MenuWrapper>
            </ButtonsGroup>
          )}
          {session.personEntities.length !== 0 && session.isMTS && (
            <FinishSessionButton
              rounded
              primary
              sessionId={sessionId}
              entityId={entityId}
              name={session.name}
              testSetId={session.testSets[0].id}
            >
              <FormattedMessage {...messages.menuItemSessionFinishSession} />
            </FinishSessionButton>
          )}
        </CardBody>
      )}

      <SessionFormInput entityId={entityId} edit={edit} setEdit={setEdit} />
    </Card>
  );
}

export default EditSessionCard;
